import { createClient } from "@supabase/supabase-js";
import { SUPABASE_URL, SUPABASE_KEY } from "../lib/constants.js";

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

// update gesture in supabase
export const syncSupaGesture = async (gesture) => {
  console.log("Syncing gesture to Supabase: ", gesture);
  await supabase
    .from("settings_game")
    .update({ value_plain: gesture, updated_at: new Date() })
    .eq("key", "gesture");
};

export const syncSupaPlayStatus = async (gameStarted) => {
  console.log("Syncing game play status to Supabase: ", gameStarted);

  await supabase
    .from("settings_game")
    .update({ value_plain: gameStarted ? 1 : 0, updated_at: new Date() })
    .eq("key", "isPlaying");
};
