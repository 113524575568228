import React, { useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./TogglePlayComponent.css";

export const TogglePlayComponent = ({ onCheckPlayChange }) => {
  // the user can indicate if the game has started by checking the checkbox
  return (
    <label className="TogglePlayComponent">
      <Toggle
        defaultChecked={false}
        onChange={(e) => {
          onCheckPlayChange(e.target.checked);
        }}
      />
      <span>Supasync</span>
    </label>
  );
};
