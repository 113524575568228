import "./App.css"; // import css
import React, { useEffect, useState, useRef, useCallback } from "react"; // import react

// import components
import { WebcamComponent } from "./Components/Webcam/WebcamComponent";
import { CamSelectComponent } from "./Components/CamSelect/CamSelectComponent";
import { GestureIconComponent } from "./Components/GestureIcon/GestureIconComponent";
import { TogglePlayComponent } from "./Components/TogglePlay/TogglePlayComponent";
import { BtnChallengeComponent } from "./Components/BtnChallenge/BtnChallengeComponent";
import { CountdownComponent } from "./Components/Countdown/CountdownComponent";

// import constants
import { FRAMES_PER_SECOND } from "./lib/constants.js";

// import helper functions
import { /*syncSupaGesture,*/ syncSupaPlayStatus } from "./lib/SupabaseHelper";
import { loadModel, predict } from "./lib/TeachableMachineHelper";
import { winnerCalculator, generateGesture } from "./lib/GamePlayHelper";

function App() {
  // states
  const [selectedCam, setSelectedCam] = useState(null);
  const [gesture, setGesture] = useState("cross-mark");
  const [aiGesture, setAiGesture] = useState("ai");
  const [handInBox, setHandInBox] = useState(false);
  const [tmParams, setTmParams] = useState({
    model: null, // teachable machine model
    webcam: null, // webcam object
    maxPredictions: null, // max predictions
  });

  const [supaSyncEnabled, setSupaSyncEnabled] = useState(false);
  const [challengeNr, setChallengeNr] = useState(0);
  // keep track of an active challenge
  const activeChallenge = useRef(false);
  const gameEnding = useRef(false);

  // constants
  const webcamRef = useRef(null);

  // handle camera change
  const handleCamChange = (e) => {
    const el = e.target;
    const deviceId = el.value;
    const label = el.options[el.selectedIndex].innerHTML;
    setSelectedCam(deviceId);
    console.log("Selected cam: ", label);
  };

  // handle game play change
  const handleGamePlayChange = (checked) => {
    setSupaSyncEnabled(checked);
    console.log("Connected to Supabase: ", checked);
  };

  // handle challenge button click
  const handleChallengeClick = () => {
    // set active challenge to true, so the gesture is not overwritten
    setChallengeNr(challengeNr + 1);
    activeChallenge.current = true;

    // console.log("Challenge nr: ", challengeNr);
  };

  // handle countdown end
  const handleCountdownEnd = useCallback(async () => {
    // predict gesture and set gesture state
    const result = await predict(tmParams);
    setGesture(result.prediction.icon);

    // if no hands in box, set ai icon to ai-icon
    if (result.prediction.icon === "cross-mark") {
      setAiGesture("ai");
    } else {
      // generator ai gesture and set ai icon
      setAiGesture(generateGesture());
    }

    // set active challenge to false after 2 seconds
    setTimeout(() => {
      activeChallenge.current = false;
      gameEnding.current = false;
    }, 2000);

    gameEnding.current = true;
  }, [tmParams]);

  // loop function
  const loop = useCallback(async () => {
    // update the webcam frame every loop iteration
    tmParams.webcam.update();

    const result = await predict(tmParams);

    // check if hand is in box
    if (result.prediction.icon !== "cross-mark") {
      setHandInBox(true);

      // set gesture state to human if no active challenge
      if (!activeChallenge.current) {
        setGesture("human");
        setAiGesture("ai");
      }
    } else {
      setHandInBox(false);
      setGesture("cross-mark");
      if (!gameEnding.current) {
        handleCountdownEnd();
      }
    }

    // wait some time before looping again, so we don't overload the browser
    setTimeout(() => {
      window.requestAnimationFrame(loop);
    }, 1000 / FRAMES_PER_SECOND);
  }, [tmParams, handleCountdownEnd]);

  // update game started in supabase
  useEffect(() => {
    syncSupaPlayStatus(supaSyncEnabled);
  }, [supaSyncEnabled]);

  // start the webcam loop when model is loaded
  useEffect(() => {
    if (!tmParams.model || !tmParams.webcam || !tmParams.maxPredictions) {
      return;
    }

    window.requestAnimationFrame(loop);
  }, [tmParams, loop]);

  // load model when camera is selected
  useEffect(() => {
    // return if no camera is selected
    if (!selectedCam) return;

    // load model, when loaded set states
    loadModel(selectedCam).then((modelProps) => {
      // set model, webcam and max predictions
      setTmParams(modelProps);
    });
  }, [selectedCam]);

  return (
    <div className="App">
      <WebcamComponent device={selectedCam} webcamRef={webcamRef} />

      <GestureIconComponent
        gesture={gesture}
        className="GestureIconComponent human"
        title="Human"
      />
      <GestureIconComponent
        gesture={aiGesture}
        className="GestureIconComponent ai"
        title="AI"
      />

      <CountdownComponent
        challengeNr={challengeNr}
        initialSeconds="3"
        onCountdownEnd={handleCountdownEnd}
        gameResult={winnerCalculator(gesture, aiGesture)}
      />

      {!selectedCam ? (
        <CamSelectComponent
          onCamChange={(e) => {
            handleCamChange(e);
          }}
          webcamRef={webcamRef}
        />
      ) : (
        ""
      )}

      {tmParams.model &&
      tmParams.webcam &&
      tmParams.maxPredictions &&
      handInBox &&
      !activeChallenge.current ? (
        <BtnChallengeComponent
          show={tmParams.model && tmParams.webcam && tmParams.maxPredictions}
          onClick={handleChallengeClick}
        />
      ) : (
        ""
      )}

      <TogglePlayComponent onCheckPlayChange={handleGamePlayChange} />
    </div>
  );
}

export default App;
