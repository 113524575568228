import React, { useEffect, useState, useRef, useCallback } from "react";
import "./CountdownComponent.css";

/** this componnent will countdown from three to 1 */
export const CountdownComponent = ({
  challengeNr,
  initialSeconds,
  onCountdownEnd,
  gameResult,
}) => {
  const [className, setClassName] = useState("CountdownComponent hide");
  const [output, setOutput] = useState("");

  const lastChallengeNr = useRef(challengeNr);

  useEffect(() => {
    setOutput("");
  }, [gameResult]);

  const handleCountdownEnd = useCallback(() => {
    // set className to show bazinga
    setClassName("CountdownComponent show bazinga");

    // set doneCountingDown to true, which will trigger the onCountdownEnd callback
    onCountdownEnd();

    // hide countdown after 2 seconds
    setTimeout(() => {
      setClassName("CountdownComponent hide");
      setOutput("hang on...");
    }, 2000);

    // onCountdownEnd();
  }, [onCountdownEnd]);

  // countdown from three to 1, only if new challenge has begun
  useEffect(() => {
    // return if challenge has not changed
    if (lastChallengeNr.current === challengeNr) return;
    // update last challenge nr
    lastChallengeNr.current = challengeNr;

    setClassName("CountdownComponent show");

    let intSeconds = initialSeconds;

    const interval = setInterval(() => {
      if (intSeconds > 0) {
        setOutput(intSeconds);
        intSeconds--;
      } else {
        // stop interval
        clearInterval(interval);
        // call callback
        handleCountdownEnd();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [challengeNr, initialSeconds, handleCountdownEnd]);

  // return the countdown timer, which is only visible when the challenge has begun
  return (
    <div className={className}>
      <h1 dangerouslySetInnerHTML={{ __html: gameResult || output }} />
    </div>
  );
};
