import "./WebcamComponent.css";
import React from "react";
import Webcam from "react-webcam";

export const WebcamComponent = ({ device, webcamRef }) => {
  console.log(device);
  if (!device) return "";

  return (
    <>
      <div className="WebcamComponent">
        <div className="gradient gradientBefore"></div>
        <Webcam
          screenshotFormat="image/jpeg"
          videoConstraints={{
            width: 200,
            height: 200,
            facingMode: "user",
            deviceId: device?.deviceId,
          }}
          mirrored={true}
          autoPlay={true}
          audio={false}
          ref={webcamRef}
        />
        <div className="gradient gradientAfter"></div>
      </div>
    </>
  );
};
