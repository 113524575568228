import { GESTURES } from "./constants";

/*
 * GestureCalculator
 *  - takes two gestures as arguments
 *  - returns a string with the result of the game
 *  - returns undefined if one of the gestures is not found
 * The calculator is based on the following rules:
 * - rock beats scissors and lizard
 * - scissors beats paper and lizard
 * - paper beats rock and spock
 * - lizard beats paper and spock
 * - spock beats rock and scissors
 * There is an algorithm to calculate the winner:
 * https://medium.com/@jp.mfichtl/rock-paper-scissors-lizard-spock-or-why-math-is-awesome-for-coding-405dabe30f4
 *
 */
export const winnerCalculator = (gesture, aiGesture) => {
  // get indexes of gestures
  const human = GESTURES.indexOf(gesture);
  const computer = GESTURES.indexOf(aiGesture);

  // return if no hands in the box
  if (gesture === "cross-mark") {
    return "404<br> Hand not found!";
  }

  // return if gesture or aiGesture is not found
  if (human === -1 || computer === -1) return;

  // calculate winner
  const compare = human - computer + 5;
  const result = compare % 5;

  if (result === 0) {
    return "It's a tie!";
  } else if ((result + 1) % 2 === 0) {
    return "Bazinga! <br> You win!";
  } else if (result % 2 === 0) {
    return "Aww&hellip;<br> you loose!";
  }
};

// generate random gesture and return it
export const generateGesture = () => {
  const randomIndex = Math.floor(Math.random() * GESTURES.length);
  return GESTURES[randomIndex];
};
